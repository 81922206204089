<template>
  <div class="cont">
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="11">
        <img :src="url[0]" alt />
      </el-col>
      <el-col :span="13">
        <h2 class="info-title">{{ info.title }}</h2>
        <div class="info" v-html="info.req"></div>
      </el-col>
    </el-row>
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="11">
        <h2 class="info-title">{{ info.tit }}</h2>
        <div class="info" v-html="info.desc"></div>
      </el-col>
      <el-col :span="13">
        <img :src="url[1]" alt />
      </el-col>
    </el-row>
    <h2 class="info-title">{{ info.gn }}</h2>
    <p class="info" v-html="info.cont"></p>
  </div>
</template>
<script>
export default {
  name: 'Nomalcert',
  props: ['url', 'info'],
}
</script>
<style lang="less" scoped>
.cont {
  .info-title {
    color: #000005;
    font-size: 30px;
    padding-bottom: 30px;
  }
  .info {
    font-size: 16px;
    color: #333;
    line-height: 30px;
    /deep/p {
      padding-bottom: 20px;
    }
  }
  .el-row {
    margin-bottom: 50px;
  }
  img {
    width: 90%;
  }
}
</style>
