<template>
  <div class="news">
    <Normaltop
      :url="qiniuUrl"
      title="认证体系"
      en="Certification System"
    ></Normaltop>
    <div class="news-cont">
      <normalcert :url="url" :info="info"></normalcert>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normalcert from '../components/nomalcert'
import Normaltop from '../components/normaltop'
export default {
  name: 'Certificate',
  components: { Normalcert, Normaltop },
  data() {
    return {
      news: [],
      qiniuUrl: this.$qiniuUrl + 'rz/bg.png',
      url: [
        require('../assets/images/rz/img3.png'),
        require('../assets/images/rz/img4.png'),
      ],
      info: {
        title: '高级网培师认证介绍',
        desc: `<p>
           1.坚持社会主义核心价值观，充分了解国家在互联网管理及互联网教学培训管理方面的相关政策和法规，并长期遵照相关法规执行。<br/>
2.已完成“网培师”阶段的课程学习评测认证。<br/>
3.具有正确教育学生的能力，能够根据网络班级的教学场景、学生年龄段、以及学生的思想实际进行教学，有丰富的一对多在线课堂的教学工作经验，并较好地完成任务。<br/>
4.对所教领域有比较扎实的基础理论和专业知识，独立掌握所教课程的课程标准、教材、教学原则和教学方法，教学经验丰富，有较强的专业知识技能，教学效果好。<br/>
5.具有较强的组织和开展教育教学研究的能力，并曾承担一定的教学研究任务，做出具有创新性的教学课件。<br/>
6.在培养、指导同行网培师提高业务水平和教育教学能力方面曾做出一定成绩。<br/>
7.具有三年及以上行业工作经验，累计网络授课课时数在三千小时以上，或者制作的录播课程在120课时以上且课程视频累计播放量达到一万以上，普通话水平达到二级甲等。
        </p>
        `,
        req: `<p>已获得网培师证且已持证工作1年以上，可申请高级网培师资格认证，参与者需要：<br/>
1.登录本平台，在个人中心里我的作品项，上传不少于3个本人平时优秀教学视频作品；<br/>
2.上传相关的辅助材料，如教师资格证、推荐信、教学获奖证书等。<br/>
上传材料，将由网培师评测认证中心组织专家团队进行评审，评审通过后，获得高级网培师资格，并由发证方颁发高级网培师资格证书，同时可获得由教育区块链学习银行颁发的电子证书。
          </p>`,
        tit: '高级网培师认证要求',
        gn: '高级网培师的能力概述',
        cont: `获得高级网培师认证证书后，应具备以下能力：<br/>
1. 对网络培训中涉及到的国家各项政策法规以及各平台的规定都有较好的理解，熟练掌握网络培训中相关礼仪与着装注意事项，并能够对网培师的相关能力进行判断，对不合要求的地方进行指导。<br/>
2. 具有正确教育学生的能力，能够根据网班教学场景、学生年龄段、以及学生的思想实际进行教学，演讲能力出色，有比较丰富的大型在线课堂的教学工作经验，并较好地完成任务。<br/>
3. 对所教领域有比较扎实的基础理论和专业知识，能独立制定相关课程的课程标准、教材、教学原则和教学方法，教学经验比较丰富，有较好的专业知识技能，现场效果良好。<br/>
4. 掌握教育教学研究方法，积极开展教育教学研究和创新实践，具备良好的心理素质，在大型在线的公开课，能够克服面对众多听众的压力，拥有较强的抗压能力，同时具有一定的组织和开展教育教学研究的能力，并承担一定的教学研究任务，设计编写的PPT、教材等资料新颖和有创意。`,
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.news = [
        {
          title: '新闻标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 1,
          id: 1,
        },
        {
          title: '公告标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 2,
          id: 2,
        },
        {
          title: '新闻标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 1,
          id: 3,
        },
      ]
    },
    toNewsDesc(id) {
      this.$router.push({ name: 'NewsDesc', params: { id: id } })
    },
  },
}
</script>
<style lang="less" scoped>
.news {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  .news-cont {
    width: 60%;
    min-width: 1100px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .part {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #e2e2e2;
      .date-box {
        width: 100px;
        height: 100px;
        color: #797979;
        background: #e9ebf0;
        text-align: center;
        h1 {
          font-weight: normal;
          font-size: 48px;
        }
      }
      .news-title {
        color: #000000;
        font-weight: bold;
        font-size: 18px;
        padding-left: 10px;
        .red-text {
          color: #960f23;
        }
        .yellow-text {
          color: #c9850e;
        }
      }
      .news-desc {
        padding: 10px 20px;
        color: #333;
        line-height: 1.5;
      }
    }
  }
}
</style>
